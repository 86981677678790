import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { HeadingAccent, Text, SVG, AppLink } from "@ui";
import { t } from "@translations";

const CurriculumSingleCollectionList = ({ collections }) => {
  // re-hydration fix :(
  const [rendered, setRendered] = useState();
  useEffect(() => setRendered(true));
  if (!rendered) {
    return null;
  }

  return (
    <div className="mt-8">
      <HeadingAccent />
      <Text preset="h3">{t("Found in these collections")}</Text>
      <ul className="mt-6">
        {collections.map(({ uid, title, illustration }) => (
          <li
            key={uid}
            className="bg-blue text-white mb-3 hover:scale-102 transition-fast"
          >
            <AppLink
              to={`/curriculum/collections/${uid}`}
              className="block flex"
            >
              <div className="flex-1 p-6">
                <Text preset="label" className="text-gray-2 capitalize">
                  {t("collection")}:
                </Text>
                <Text preset="h4">{title}</Text>
              </div>

              <div className="w-1/3 relative">
                <div className="absolute inset-0 overflow-hidden -mt-2">
                  <SVG svg={illustration.text} />
                </div>
              </div>
            </AppLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

CurriculumSingleCollectionList.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      illustration: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default CurriculumSingleCollectionList;
