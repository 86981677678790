import React, { useEffect } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import qs from "query-string";
import { ContentBuilder } from "@modules/contentBuilder/index";
import { SEO } from "@common";
import { Section, Container } from "@ui";
import { usePosterData } from "@staticQueries";

import CurriculumSingleHero from "./CurriculumSingleHero";
import CurriculumSingleDownloadList from "./CurriculumSingleDownloadList";
import CurriculumSingleCollectionNav from "./CurriculumSingleCollectionNav";
import CurriculumSingleCollectionList from "./CurriculumSingleCollectionList";
import CurriculumSingleNextMaterial from "./CurriculumSingleNextMaterial";
import CurriculumSingleRelatedMaterials from "./CurriculumSingleRelatedMaterials";
import CurriculumSinglePoster from "./CurriculumSinglePoster";

/* eslint react/prop-types: 0 */

const CurriculumSingle = ({ data, location }) => {
  const [, dispatch] = useAppState();

  // get collection uid from query params
  const { cuid } = qs.parse(location.search);

  const {
    uid,
    type,
    title,
    summary,
    delivery,
    corQuestion,
    disciplines,
    illustration,
    downloads,
    collections,
    body,
    relatedMaterials,
  } = data;

  const poster = usePosterData();

  const color = corQuestion.color ? corQuestion.color.toLowerCase() : "blue";

  const isLesson = type === "lessons";

  const showCollections = !cuid && collections.length > 0;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: color,
        showSearch: true,
        lockup: {
          size: "small",
          color,
        },
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cuid]);

  return (
    <>
      <SEO title={title} description={summary.text} />

      <Section
        className={classNames({
          [`bg-${color}`]: true,
          "text-white": color !== "yellow",
          "text-gray": color === "yellow",
        })}
        hasPaddingTop={false}
        hasPaddingBottom={false}
      >
        <CurriculumSingleHero
          type={type}
          title={title}
          delivery={delivery}
          color={color}
          corQuestion={corQuestion}
          disciplines={disciplines}
          illustration={illustration}
          cuid={cuid}
        />
      </Section>

      <Section hasPaddingTop={false} allowOverflow className="relative z-10">
        <Container className="md:flex md:flex-row-reverse">
          <div className="md:w-1/3" style={{ transform: "translateY(-3rem)" }}>
            <CurriculumSingleDownloadList
              downloads={downloads}
              isLesson={isLesson}
            />

            <div className="hidden md:block">
              <CurriculumSinglePoster {...poster} />

              {showCollections && (
                <CurriculumSingleCollectionList collections={collections} />
              )}

              {cuid && (
                <CurriculumSingleCollectionNav
                  collectionId={cuid}
                  materialId={uid}
                />
              )}
            </div>
          </div>

          <div className="md:w-2/3 md:pt-12 md:pr-24">
            <ContentBuilder blocks={body} />
          </div>

          <div className="md:hidden">
            <CurriculumSinglePoster {...poster} />

            {showCollections && (
              <CurriculumSingleCollectionList collections={collections} />
            )}

            {cuid && (
              <CurriculumSingleCollectionNav
                collectionId={cuid}
                materialId={uid}
              />
            )}
          </div>
        </Container>
      </Section>

      {cuid && (
        <CurriculumSingleNextMaterial collectionId={cuid} materialId={uid} />
      )}

      {!cuid && (
        <CurriculumSingleRelatedMaterials
          uid={uid}
          overrides={relatedMaterials}
        />
      )}
    </>
  );
};

export default CurriculumSingle;
