import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Text, Button, AuthButton } from "@ui";
import { t } from "@translations";
import { trackGAEvent } from "@utils";

const CurriculumSingleDownload = ({
  title,
  link,
  isLesson,
  isEven,
  isLast,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ user }] = useAppState();

  const handleDownload = () => {
    trackGAEvent({ eventLabel: link.url });
    window.open(link.url, "_blank");
  };

  const DownloadButton = user.loggedIn ? Button : AuthButton;

  useEffect(() => setRendered(true), []);

  return (
    <li
      className={classNames({
        transition: true,
        "flex items-center py-2": isLesson,
        "border-b-1 border-gray-2": isLesson && !isLast,
        "py-3": !isLesson,
        "opacity-0": !rendered,
        "opacity-100": rendered,
      })}
    >
      {isLesson && (
        <Text preset="h4" className="flex-1 mr-2">
          {title}
        </Text>
      )}

      {isLesson ? (
        <DownloadButton
          preset="empty"
          text={t("access")}
          className="text-blue"
          onClick={user.loggedIn ? handleDownload : null}
          modalName="signInModal"
        />
      ) : (
        <DownloadButton
          preset={isEven ? "blue" : "white"}
          text={title}
          className="w-full"
          onClick={user.loggedIn ? handleDownload : null}
          modalName="signInModal"
        />
      )}
    </li>
  );
};

CurriculumSingleDownload.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    kind: PropTypes.string,
  }).isRequired,
  isLesson: PropTypes.bool.isRequired,
  isEven: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default React.memo(CurriculumSingleDownload);
