import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { CurriculumCard } from "@modules/curriculum/index";
import { Text, Carousel, Section, Container } from "@ui";
import { t } from "@translations";

function getNeighbors(idx, lastIdx) {
  if (idx === -1) {
    return [];
  }
  if (idx === 0) {
    return [1, 2, 3, 4];
  }
  if (idx === 1) {
    return [0, 2, 3, 4];
  }
  if (idx === lastIdx) {
    return [lastIdx - 1, lastIdx - 2, lastIdx - 3, lastIdx - 4];
  }
  if (idx === lastIdx - 1) {
    return [lastIdx, lastIdx - 2, lastIdx - 3, lastIdx - 4];
  }
  return [idx - 1, idx - 2, idx + 1, idx + 2];
}

const RelatedMaterials = ({ uid, overrides }) => {
  const [{ search, breakpoints }] = useAppState();
  const { sm } = breakpoints;
  const { content } = search;

  // get all lessons and assessments
  const materials = content.filter(
    item => item.type === "lessons" || item.type === "assessments"
  );

  // abort if no materials, meaning state is still being set
  if (materials.length === 0) {
    return null;
  }

  // get the override indexes from passed uids/types
  // Note: having to check if the idx === -1 in case this material
  // or override materials haven't been added to the master sort.
  const indexes = overrides
    .filter(
      ({ uid: _uid, type }) =>
        materials.findIndex(item => item.uid === _uid && item.type === type) >
        -1
    )
    .map(({ uid: _uid, type }) =>
      materials.findIndex(item => item.uid === _uid && item.type === type)
    );

  // get the index of the current item
  let idx = materials.findIndex(item => item.uid === uid);
  if (idx === -1) {
    idx = 0;
  }

  // get list of neighbors and append to indexes if not already included
  const neighbors = getNeighbors(idx, materials.length - 1);
  neighbors.forEach(neighbor => {
    if (!indexes.includes(neighbor)) {
      indexes.push(neighbor);
    }
  });

  // map indices to material objects
  const items = indexes.slice(0, 4).map(_idx => materials[_idx]);

  // abort if no items
  if (items.length === 0) {
    return null;
  }

  const getSlides = isCarousel =>
    items.map(({ id, uid: _uid, title, type, illustration, tags, color }) => (
      <CurriculumCard
        key={id}
        uid={_uid}
        title={title}
        type={type}
        illustration={illustration}
        tags={tags}
        color={color}
        disableHoverStyle={isCarousel}
        className={`w-full sm:w-1/2 lg:w-1/4 ${
          isCarousel ? "h-full" : "h-auto"
        }`}
      />
    ));

  return (
    <Section size="sm" className="bg-gray-1 sm:rounded-lg">
      <Container className="mb-6 lg:mb-8">
        <Text preset="h2" className="capitalize">
          {t("related curriculum")}
        </Text>
      </Container>

      {sm ? (
        <Container>
          <div className="flex flex-wrap -mx-3">{getSlides()}</div>
        </Container>
      ) : (
        <Carousel
          infinite={false}
          stagePadding={28}
          overflowVisible
          showDots={false}
          showButtons={false}
        >
          {getSlides(true)}
        </Carousel>
      )}
    </Section>
  );
};

RelatedMaterials.propTypes = {
  uid: PropTypes.string.isRequired,
  overrides: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RelatedMaterials;
