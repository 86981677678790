import React from "react";
import PropTypes from "prop-types";
import {
  Text,
  Button,
  SVG,
  AppLink,
  RandomShapes,
  Section,
  Container,
} from "@ui";
import { useAllCollectionsData } from "@staticQueries";
import { t } from "@translations";

const NextMaterial = ({ collectionId, materialId }) => {
  const collections = useAllCollectionsData();
  const { materials } = collections.find(item => item.uid === collectionId);
  const thisIdx = materials.findIndex(item => item.uid === materialId);
  const nextMaterial = materials[thisIdx + 1];
  if (!nextMaterial) {
    return null;
  }

  const { uid, type, title, illustration, corQuestion } = nextMaterial;
  const color = corQuestion.color.toLowerCase();
  const textColor = color === "yellow" ? "text-gray" : "text-white";
  const typeName = type.slice(0, -1);

  return (
    <Section
      className={`relative bg-${color} ${textColor}`}
      hasPaddingTop={false}
      hasPaddingBottom={false}
    >
      <RandomShapes opacity={color === "yellow" ? 0.2 : undefined} />
      <Container className="relative z-10 flex items-center">
        <div className="flex-1 my-6 md:my-0">
          <Text preset="label" className="font-bold capitalize">
            {`${t("next")} ${t(typeName)}:`}
          </Text>
          <Text preset="h2" className="mb-6">
            {title}
          </Text>
          <AppLink to={`/curriculum/${type}/${uid}?cuid=${collectionId}`}>
            <Button
              preset="white"
              text={`${t("view")} ${t(typeName)}`}
              className="text-blue"
            />
          </AppLink>
        </div>

        <div className="w-1/2 md:w-1/3 relative md:mt-12 md:translate-x-50 self-end">
          <div className="w-full" style={{ paddingBottom: "100%" }}>
            <div className="absolute inset-0">
              <SVG svg={illustration.text} className="w-full h-full" />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

NextMaterial.propTypes = {
  collectionId: PropTypes.string.isRequired,
  materialId: PropTypes.string.isRequired,
};

export default NextMaterial;
