import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import CurriculumSingle from "../../pageTemplates/CurriculumSingle/CurriculumSingle";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import { useAllCollectionsData } from "@staticQueries";
import resolveSlices from "../dataResolvers/resolveSlices";
import mergePrismicPreview from "../mergePrismicPreview";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const levelMap = {
  Simple: "Level 1",
  Complex: "Level 2",
};

const dataResolver = ({ prismicLessons }) => {
  const { uid, type, data } = prismicLessons;
  const {
    cor_question,
    disciplines,
    downloads,
    related_materials,
    body,
    ...rest
  } = data;

  const collections = useAllCollectionsData()
    .filter(({ materials }) => {
      return materials.find(material => material.uid === uid);
    })
    .map(({ uid: _uid, title, illustration }) => ({
      uid: _uid,
      title,
      illustration,
    }));

  const resolved = {
    data: {
      ...rest,
      uid,
      type,
      corQuestion: resolveEmbeddedDocument(cor_question) || {},
      disciplines: resolveEmbeddedDocument(disciplines) || {},
      downloads: downloads.map(item => ({
        level: t(levelMap[item.primary.level]),
        items: item.items,
      })),
      collections,
      body: resolveSlices(body),
      relatedMaterials: related_materials
        .filter(material => material.material.uid && material.material.type)
        .map(material => ({
          uid: material.material.uid,
          type: material.material.type,
        })),
    },
  };

  return keysToCamel(resolved);
};

const LessonQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <CurriculumSingle {...data} location={location} />;
};

export default LessonQuery;

export const query = graphql`
  query($uid: String!) {
    prismicLessons(uid: { eq: $uid }) {
      uid
      type
      data {
        title
        illustration {
          text
        }
        summary {
          text
        }
        related_materials {
          material {
            uid
            type
          }
        }
        body {
          ... on PrismicLessonsBodyCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicLessonsBodyImages {
            id
            slice_type
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
                alt
              }
              focal_point_x
              focal_point_y
              filter
              caption {
                html
              }
            }
          }
          ... on PrismicLessonsBodyVideo {
            id
            slice_type
            primary {
              cta_text
              video {
                document {
                  ... on PrismicVideos {
                    id
                    data {
                      title
                      description {
                        html
                      }
                      youtube_video_id
                      thumbnail {
                        image {
                          url
                          dimensions {
                            width
                            height
                          }
                          alt
                        }
                        focal_point_x
                        focal_point_y
                      }
                    }
                  }
                }
              }
            }
          }
        }
        downloads {
          ... on PrismicLessonsDownloadsLessonDownloadGroup {
            id
            primary {
              level
            }
            items {
              title
              link {
                kind
                url
                target
              }
            }
          }
        }
        cor_question {
          document {
            ... on PrismicCorQuestions {
              id
              data {
                color
                title
              }
            }
          }
        }
        disciplines {
          discipline {
            document {
              ... on PrismicDisciplines {
                id
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
