import React from "react";
import PropTypes from "prop-types";
import { Text, PDFThumbnail, AppLink, Button } from "@ui";

const CurriculumSinglePoster = ({ heading, thumbnail, links }) => {
  if (thumbnail) {
    return (
      <div className="flex items-center my-8 p-4 border-2 border-gray-1">
        <PDFThumbnail
          url={thumbnail}
          aspectRatio={0.75}
          alt={heading}
          className="w-24 mr-4"
        />
        <div className="flex-auto">
          <Text preset="h3">{heading}</Text>
          <div className="flex">
            {links.map(({ url, ctaText }) => (
              <AppLink key={url} to={url} target="_blank" className="mr-4">
                <Button preset="empty" className="text-blue" text={ctaText} />
              </AppLink>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

CurriculumSinglePoster.propTypes = {
  heading: PropTypes.string,
  thumbnail: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      ctaText: PropTypes.string.isRequired,
    })
  ),
};

CurriculumSinglePoster.defaultProps = {
  heading: "",
  thumbnail: "",
  links: [],
};

export default CurriculumSinglePoster;
