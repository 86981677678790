import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text, SVG, Button, AppLink } from "@ui";
import { useAllCollectionsData } from "@staticQueries";
import { t } from "@translations";

const CurriculumSingleCollectionNav = ({ collectionId, materialId }) => {
  const collections = useAllCollectionsData();
  const { title, illustration, materials } = collections.find(
    item => item.uid === collectionId
  );

  return (
    <div className="mt-8">
      <div className="block flex bg-blue">
        <div className="flex-1 p-6">
          <Text preset="label" className="text-gray-2 capitalize">
            {t("collection")}:
          </Text>
          <Text preset="h4" className="text-white">
            {title}
          </Text>
        </div>

        <div className="w-1/3 relative">
          <div className="absolute inset-0 overflow-hidden -mt-2">
            <SVG svg={illustration.text} />
          </div>
        </div>
      </div>

      <ul className="bg-white px-6 py-3 border-2 border-gray-1 border-t-0">
        {materials.map(
          (
            {
              id,
              uid,
              type,
              title: _title,
              illustration: _illustration,
              corQuestion,
            },
            idx
          ) => (
            <li
              key={id}
              className="my-3 shadow hover:scale-102 transition-fast"
            >
              <AppLink
                to={`/curriculum/${type}/${uid}?cuid=${collectionId}`}
                className={classNames({
                  "flex items-center": true,
                  "text-gray": uid !== materialId,
                  [`bg-${corQuestion.color.toLowerCase()} text-white`]:
                    uid === materialId,
                })}
              >
                <div
                  className={`bg-${corQuestion.color.toLowerCase()} w-12 relative`}
                >
                  <div className="w-full" style={{ paddingBottom: "100%" }}>
                    <div className="absolute inset-0">
                      <SVG svg={_illustration.text} className="w-full h-full" />
                    </div>
                  </div>
                </div>

                <div className="flex-1 px-3 min-w-0">
                  <Text preset="label" className="block font-bold">
                    {idx < 9 ? `0${idx + 1}` : idx + 1}
                  </Text>
                  <Text preset="h4" className="font-bold truncate">
                    {_title}
                  </Text>
                </div>
              </AppLink>
            </li>
          )
        )}

        <AppLink to={`/curriculum/collections/${collectionId}`}>
          <Button
            preset="empty"
            text={t("View the collection")}
            className="text-blue"
          />
        </AppLink>
      </ul>
    </div>
  );
};

CurriculumSingleCollectionNav.propTypes = {
  collectionId: PropTypes.string.isRequired,
  materialId: PropTypes.string.isRequired,
};

export default CurriculumSingleCollectionNav;
