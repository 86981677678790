import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Text, Button, SVG, AppLink, RandomShapes, Container } from "@ui";
import { t } from "@translations";

const Hero = ({
  type,
  title,
  delivery,
  corQuestion,
  disciplines,
  color,
  illustration,
  cuid,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ search, cards }, dispatch] = useAppState();

  const Back = () => {
    const text = cuid ? t("View collection") : t("All lessons & assessments");
    let backUrl = cuid ? `/curriculum/collections/${cuid}/` : "/curriculum/";
    if (cards.selectedCard) {
      backUrl += `#${cards.selectedCard}`;
    }

    return <BackInner text={text} url={backUrl} />;
  };

  const BackInner = ({ text, url }) => {
    const onClick = () => {
      if (search.filters.type !== "singles") {
        dispatch({
          type: "setFilters",
          filters: {
            ...search.filters,
            type: "singles",
          },
        });
      }
    };

    return (
      <AppLink to={url}>
        <Button
          text={t(text)}
          preset="bordered"
          size="sm"
          className={
            color === "yellow"
              ? "hover:bg-gray hover:text-white hover:border-gray"
              : "hover:bg-white hover:text-gray hover:border-white"
          }
          iconName="Arrow"
          iconClassName="w-4 h-3"
          onClick={onClick}
        />
      </AppLink>
    );
  };

  BackInner.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  useEffect(() => setRendered(true), []);

  return (
    <div className="pt-12 lg:pt-24">
      <RandomShapes opacity={color === "yellow" ? 0.2 : 0.13} />
      <Container className="relative z-10 flex flex-col lg:flex-row lg:items-end pt-8 pb-24 md:py-8">
        <div className="flex flex-col flex-1">
          <div>
            <Back />
          </div>

          <div className="my-6 md:my-12 lg:my-24">
            <div className="bg-gray-1 inline-flex items-center h-5 px-2 rounded-full mb-4">
              <Text
                preset="label"
                variant="sm"
                className="font-bold text-gray-5 capitalize"
              >
                {t(type.slice(0, -1))}
              </Text>
            </div>
            <Text preset="h1">{title}</Text>
          </div>

          <div
            className="md:hidden w-full relative mb-6"
            style={{ paddingBottom: "100%" }}
          >
            <span
              className={classNames({
                "absolute top-0 right-0 left-0 bottom-0 transition-slow-expo": true,
                "opacity-0 illo": !rendered,
                "opacity-100": rendered,
              })}
            >
              <SVG svg={illustration.text} />
            </span>
          </div>

          <div className="flex items-start">
            {delivery && (
              <div className="mr-8 md:mr-12">
                <Text
                  preset="label"
                  className="block font-bold text-xs mb-2 capitalize"
                >
                  {t("delivery")}:
                </Text>
                <Text preset="label" className="block font-bold text-sm">
                  {delivery}
                </Text>
              </div>
            )}

            <div className="mr-8 md:mr-12">
              <Text
                preset="label"
                className="block font-bold text-xxs mb-2 capitalize"
              >
                {t("COR Question")}:
              </Text>
              <Text preset="label" className="block font-bold text-sm">
                {corQuestion.title}
              </Text>
            </div>

            {disciplines.length > 0 && (
              <div>
                <Text
                  preset="label"
                  className="block font-bold text-xxs mb-2 capitalize"
                >
                  {t("Discipline")}:
                </Text>
                {disciplines.map(({ name, id }) => (
                  <Text
                    key={id}
                    preset="label"
                    className="font-bold text-sm comma-delim"
                  >
                    {name}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="hidden md:block lg:w-1/3 lg:mb-4">
          <div className="w-full relative" style={{ paddingBottom: "100%" }}>
            <span
              className={classNames({
                "absolute top-0 right-0 left-0 bottom-0 transition-slow-expo": true,
                "opacity-0 illo": !rendered,
                "opacity-100": rendered,
              })}
            >
              <SVG
                svg={illustration.text}
                setExplicitHeight
                className="h-full"
              />
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  delivery: PropTypes.string,
  corQuestion: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  disciplines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.string.isRequired,
  illustration: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  cuid: PropTypes.string,
};

Hero.defaultProps = {
  delivery: null,
  cuid: null,
};

export default Hero;
