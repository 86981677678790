import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Text, RichText, Tabs, AuthButton } from "@ui";
import Download from "./CurriculumSingleDownload";
import { useDownloadsData } from "@staticQueries";
import { t } from "@translations";

const CurriculumSingleDownloadList = ({ downloads, isLesson }) => {
  const [tab, setTab] = useState(0);
  const [{ user }] = useAppState();
  const { loggedIn } = user;

  const tabs = downloads.map((item, idx) => ({
    id: idx,
    title: t(item.level),
    onClick: () => setTab(idx),
    isSelected: tab === idx,
  }));

  const {
    loggedInHeading,
    loggedInDescriptionSingle,
    loggedInDescriptionMulti,
    loggedOutHeading,
    loggedOutDescription,
  } = useDownloadsData();

  return (
    <div className="w-full bg-white rounded-lg shadow-lg p-2">
      <div className="p-4 pb-6 text-center">
        <Text preset="h3" className="text-blue mb-4">
          {loggedIn ? loggedInHeading : loggedOutHeading}
        </Text>
        {loggedIn ? (
          <RichText
            html={
              tabs.length === 1
                ? loggedInDescriptionSingle.html
                : loggedInDescriptionMulti.html
            }
            className="RichText--legaleze RichText--inline font-bold leading-firm"
          />
        ) : (
          <RichText
            html={loggedOutDescription.html}
            className="RichText--legaleze RichText--inline font-bold leading-firm"
          />
        )}

        {!loggedIn && (
          <AuthButton
            text={`${t("Sign in")} / ${t("Sign up")}`}
            preset="empty"
            className="text-blue -my-3 ml-2"
            modalName="signInModal"
            applySentenceCase={false}
          />
        )}
      </div>

      {tabs.length > 1 && (
        <div className="flex">
          <Tabs items={tabs} color="gray" />
        </div>
      )}

      <ul
        className={classNames({
          "bg-gray-1 px-6 py-4": true,
          "py-4": isLesson,
          "py-6": !isLesson,
          "rounded-b-lg": tabs.length > 1,
          "rounded-lg": tabs.length <= 1,
        })}
      >
        {downloads[tab] &&
          downloads[tab].items.map((item, idx) => (
            <Download
              key={`${tab}-${idx}`}
              title={item.title}
              link={item.link}
              isLesson={isLesson}
              isEven={idx % 2 === 0}
              isLast={idx === downloads[tab].items.length - 1}
            />
          ))}
      </ul>
    </div>
  );
};

const linkProps = PropTypes.shape({
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  kind: PropTypes.string,
});

CurriculumSingleDownloadList.propTypes = {
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          link: linkProps,
        })
      ),
    })
  ).isRequired,
  isLesson: PropTypes.bool.isRequired,
};

export default CurriculumSingleDownloadList;
